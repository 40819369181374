import React from "react";
import FsLightbox from 'fslightbox-react';

export const Features = () => {
  return <></>
  return (
    <div id="features" className="section bg-img" style={{ backgroundImage: `url('img/gene-calc-screen.png')` }}>
      <div className="container my-auto h-100 d-flex flex-column align-items-center">
        <div className="my-auto d-flex flex-row my-auto content">
          <div id="logo" className="text-center mr-4">
            <img src="../../public/img/logo.svg" />
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>)
}
export const GeneticsCalculator = () => (
  <div id="genetics-calculator" className="bg-primary section d-flex flex-column flex-lg-row overflow-hidden flex-column-reverse">
    {false && <div className="text-center my-auto ml-4 h-100 position-relative">
      <div className="screen-image"
        style={{ backgroundImage: `url('img/gene-calc-screen.png')` }}>

      </div>
      <img src="img/phone.png" className="h-100" />
    </div>}
    <div className="container my-auto h-100 d-flex flex-column align-items-center">
      <div className="my-auto d-flex flex-column flex-md-row my-auto content ">

        <div className="col-lg-6">
          <h2 className="display-5">Genetic Calculator</h2>
          <p>RepTool features a genetic calculator that allows users to calculate
            the
            potential genetic outcomes of breeding two reptiles together. The feature is designed for breeders,
            hobbyists,
            and professionals who work with reptiles and want to predict the offspring's genetics.</p>

          <p className="d-none">To use the feature, users first select the species of the two reptiles they want to breed, and then enter
            information about their genetics, such as morph, or genes. The app then uses this information to
            generate predictions of the genetic outcomes of the offspring, including the likelihood of certain traits
            being
            passed down and their potential combinations.</p>

          <p>With its user-friendly interface and comprehensive database, the RepTool genetic calculator is the perfect
            tool for
            anyone who wants to take their reptile breeding to the next level. Whether you're a seasoned breeder or just
            starting out, this feature will help you make informed decisions about your breeding program and achieve the
            results you're looking for.</p>
        </div>
        <div className="product-device my-auto shadow-sm col-lg-4 d-none ml-auto d-md-block"></div>

      </div>
    </div>
  </div>
)

export const Caresheet = () => (
  <div id="caresheet" className="section d-flex"
    style={{ backgroundImage: `url('img/colombian-rainbow-boa.jpg')` }}>
    <div className="container my-auto h-100 d-flex flex-column align-items-center">
      <div className="my-auto d-flex flex-row my-auto content">

        <div>
          <h2 className="display-5">Care Sheets</h2>
          <p>RepTool includes detailed care sheets for a wide range of reptile species, covering
            all
            aspects of care, from housing and temperature requirements to feeding and lighting.</p>

          <p>With its comprehensive and user-friendly design, the Reptile Care Sheet Library is the perfect resource for
            anyone who wants to ensure that their reptiles receive the best possible care. Whether you're a seasoned
            reptile keeper or just starting out, this feature will help you provide your reptiles with the love and
            attention they need to thrive.</p>
        </div>

      </div>
    </div>
  </div>
)

export const BrowseMorphs = () => {
  const pictures = ['img/browse-screen-1.png', 'img/browse-screen-2.png', 'img/browse-screen-3.png'];
  const [toggler, setToggler] = React.useState(false);
  const [activePicture, setActivePicture] = React.useState(0);
  return (
    <div id="browse-morphs" className=" section d-flex">
      <div className="container my-auto h-100 d-flex flex-column align-items-center">
        <div className="my-auto d-flex flex-column my-auto content">
          <div>
            <h2 className="display-5">Morph List</h2>
            <p>RepTool offers a comprehensive list of reptile morphs, featuring
              information on each morph, including its current market value and a list of its genetic traits. This
              information is constantly updated to ensure that users have access to the most current and accurate
              information on the market.</p>

            <p>In addition to providing users with information on current reptile morphs, this feature also
              allows users to submit their own morphs and share their knowledge with the community. Users can submit
              information on a new morph, including photos, descriptions and genetic traits.</p>

          </div>
          <div className="d-flex flex-row justify-content-around flex-wrap">
            {pictures.map((picture, index) => (
              <img key={picture} src={picture} className="img-thumbnail mt-3" onClick={() => { setActivePicture(index); setToggler(!toggler) }} style={{ cursor: 'pointer' }} />
            ))}
          </div>
          <FsLightbox
            toggler={toggler}
            sources={pictures}
            key={activePicture}
          />
        </div>
      </div>
    </div>
  )
}
export const Collection = () => {
  const pictures = [
    'img/collection-screen-1.png',
    'img/collection-screen-2.png',
    'img/collection-screen-3.png',
    'img/collection-screen-4.png',
    'img/collection-screen-5.png',
    'img/collection-screen-6.png',
    'img/collection-screen-7.png',
  ];
  const [toggler, setToggler] = React.useState(false);
  const [activePicture, setActivePicture] = React.useState(0);
  return (
    <div id="collection" className="bg-primary section d-flex">
      <div className="container my-auto h-100 d-flex flex-columnalign-items-center">
        <div className="my-auto d-flex flex-column my-auto content">

          <div>
          <h2 className="display-5">Collection Management</h2>
            <p>RepTool also features an easy-to-use platform for storing and organizing all of your reptile-related information. This feature is
              designed for reptile owners, breeders, and enthusiasts who want to keep track of their reptiles and their
              husbandry information in one convenient location.</p>

            <p>This tool allows users to store information on all of their currently owned reptiles, including their
              species, morph, and any other relevant details. Users can also create and store detailed husbandry records
              for
              each of their reptiles, including information on their feeding, housing, weight and much more.</p>

            <p>In addition to tracking their reptiles, the collection tool also includes a feature for tracking a reptile's
              family tree. Users can add parentage information for each of their reptiles and create a visual
              representation
              of their reptile's ancestry, allowing them to see the genetics behind their reptiles and understand their
              lineage.</p>

            <p>Finally, the "My collection" tool also includes a QR code feature that allows users to easily share information
              about
              their reptiles with other app users. Users can generate a unique QR code for each of their reptiles, which
              can
              then be scanned by other users to access detailed information on the reptile and its husbandry history.</p>

            <p>With its comprehensive features and user-friendly design, RepTool is the perfect tool for anyone
              who
              wants to keep track of their reptiles and their husbandry information in one convenient location. Whether
              you're a seasoned reptile keeper or just starting out, this feature will help you stay organized and on top
              of
              your reptile's health and care.</p>
          </div>
          <div className="d-flex flex-row justify-content-around flex-wrap">
            {pictures.map((picture, index) => (
              <img key={picture} src={picture} className="img-thumbnail mt-3" onClick={() => { setActivePicture(index); setToggler(!toggler) }} style={{ cursor: 'pointer' }} />
            ))}
          </div>
          <FsLightbox
            toggler={toggler}
            sources={pictures}
            key={activePicture}
          />

        </div>
      </div>
    </div>
  )
}