import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Home } from "./sections/Home.tsx";
import { About } from "./sections/About.tsx";
import { Features, Collection, Caresheet, BrowseMorphs, GeneticsCalculator } from "./sections/Features.tsx";
import { Demo } from "./sections/Demo.tsx";


const Header = () => {
  return (
    <Navbar variant={'dark'} expand="lg" className='fixed-top'>
      <Navbar.Brand href="#home">
        <div id="logo" className="text-center">
          <img src="img/logo.svg" />
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto ml-auto mr-5">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <NavDropdown title="Features" id="basic-nav-dropdown">
            <NavDropdown.Item href="#genetics-calculator">Genetic Calculator</NavDropdown.Item>
            <NavDropdown.Item href="#browse-morphs">Morph List</NavDropdown.Item>
            <NavDropdown.Item href="#caresheet">Caresheets</NavDropdown.Item>
            <NavDropdown.Item href="#collection">Collection</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="#demo">Demo</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )

}


const Footer = () => (
  <div id='footer-container'>

    <div className="container">
      <footer className="py-3">

        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Home</a></li>
          <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Features</a></li>
          <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">Pricing</a></li>
          <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">FAQs</a></li>
          <li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li>
        </ul>
        <p className="text-center text-muted">© {new Date().getFullYear()} RepTool</p>
      </footer>
    </div>
  </div>
)

const sections = {
  home: { component: Home },
  about: { component: About },
  features: {
    component: Features,
    subComponents: {
      geneticCalculator: { component: GeneticsCalculator },
      browseMorphs: { component: BrowseMorphs },
      caresheet: { component: Caresheet },
      collection: { component: Collection },
    }
  },
  demo: { component: Demo },

}


export const Landing = () => {
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      let header = document.querySelector("nav");
      header.classList.toggle("scrolled", window.scrollY > header.offsetHeight);
    })
  }, [])
  return (
    <>
      <Header />
      {Object.keys(sections).map((section) => {
        const Section = sections[section].component;
        if (sections[section].subComponents) {
          return (
            <>
              <Section />
              {Object.keys(sections[section].subComponents).map((subSection) => {
                const SubSection = sections[section].subComponents[subSection].component;
                return <SubSection />
              })}
            </>
          )
        } else {
          return <Section />
        }
      })}
      <Footer />
    </>
  )
}