import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
export const Demo = () => (
  <div id="demo" className="section d-flex flex-column flex-lg-row flex-column-reverse">
    <div className="text-center ml-4 mt-auto">
      <img id="peeker" />
    </div>
    <div className="container my-auto h-100 d-flex flex-column align-items-center">
      <div className="h-100 d-flex flex-row">

        <div className="my-auto">
          <h2 className="display-5">Free Demo</h2>
          <p>Want to try before you buy? Checkout the RepTool demo!</p>

          <p>The demo provides a hands-on look at the app's features, giving you a chance to experience the app's
            user-friendly interface and intuitive navigation for yourself. You'll be able to see how the app works, try
            out its features, and get a sense of its overall functionality and usability.</p>

          <p>Whether you're interested in learning more about the app's capabilities, or simply want to see if it's the
            right fit for your needs, this demo is the perfect starting point. With its interactive and engaging
            interface, the demo provides a compelling introduction to the app and its features, making it an excellent
            resource for anyone looking to get a better sense of what the app has to offer.</p>

          <p>So why wait? Click on the button below today and discover the power and potential of this innovative and
            user-friendly app!</p>
          <p><a className="btn btn-lg btn-outline-light" href="https://reptool.app/demo">Try it Now <FontAwesomeIcon icon={faArrowRight} size="1x" className="my-auto" /></a></p>
        </div>

      </div>
    </div>
  </div>
)