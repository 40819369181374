import logo from './logo.svg';
import './App.css';
import './css/bootstrap.min.css';
import './css/scheme.css';
import './css/landing.css';
import { Landing } from './Landing.tsx';
function App() {
  return (
    <Landing />
  );
}

export default App;
