import React from 'react';

export const Home = () => (
  <div id="home" className="section overflow-hidden">
    <div className="bg-top">
      <img src="img/BG-Top.png" />
    </div>
    <div className="container h-100 d-flex flex-column align-items-center">

      <div className="d-flex flex-column content">

        <div className="text-center col-lg-5 col-8 flex-fill mx-auto ">
          <img src="img/Icon.jpg" width="100%" />
        </div>
        <div className="mx-auto col-lg-5 flex-fill col-8">
          <img src="img/logo.svg" width="100%" />
        </div>
        <div className="mx-auto col-lg-5 flex-fill text-center mt-5 col-8">
          <h2><i>For keepers of any scale</i></h2>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-around flex-fill mt-5">
          <div className="mx-auto col-md-6 col-lg-4 col-8">
            <a href="https://reptool.app/play_store" target="_blank">
              <img src="img/play-btn.png" width="100%" className='app-store-btn'/>
            </a>
          </div>
          <div className="mx-auto col-md-6 col-lg-4 col-8">
            <a href="https://reptool.app/app_store" target="_blank">
              <img src="img/app-btn.png" width="100%" className='app-store-btn' />
            </a>
          </div>
        </div>

      </div>
      <div className="bg-bottom">
        <img src="img/BG-bottom.png" />
      </div>
    </div>
  </div>
)