import React from "react";

export const About = () => (
    <div id="about" className="section d-flex">
        <div className="container my-auto h-100 d-flex flex-column align-items-center">
            <div className="my-auto d-flex flex-column my-auto content pt-3">
                <div>
                    <h2 className="display-5">What is RepTool?</h2>
                    <p>Welcome to the world of reptiles, now made easier with our innovative reptile app! Whether you're a
                        seasoned
                        reptile keeper, breeder, or enthusiast, or just starting out on your reptile journey, our app has
                        everything
                        you need to take your reptile hobby to the next level.</p>

                    <p>With a focus on simplicity and ease of use, our app is packed with a wide range of features that cater to
                        the
                        unique needs of reptile owners and enthusiasts. From detailed care guides and husbandry information, to a
                        comprehensive library of articles and videos, our app has everything you need to keep your reptiles
                        healthy
                        and happy.</p>

                    <p>In addition to its extensive resources, our app also includes a community-driven forum where you can
                        connect
                        with other reptile enthusiasts, share your knowledge and experiences, and learn from others in the
                        community.
                        With its marketplace feature, you can also buy and sell reptiles and reptile-related items, making it the
                        perfect platform for breeders and hobbyists.</p>

                    <p>So whether you're looking to improve your reptile husbandry skills, connect with other reptile
                        enthusiasts,
                        or
                        simply find a better way to keep track of your reptiles, our app has everything you need to get started.
                        Browse through our website to learn more about our app and its features, and start your reptile journey
                        today!</p>

                </div>
            </div>
        </div>
    </div>

)
